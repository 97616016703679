.maze-generation {
  width: 100%;
  padding: var(--vertical-spacing-regular) 2rem;
}

.maze-generation > h1 {
  margin: 0;
}

.showcase {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: var(--vertical-spacing-regular);
}

.algorithm-diplayer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 32rem;
  gap: 1rem;
}

@media only screen and (max-width: 600px) {
  .algorithm-diplayer {
    display: flex;
    flex-direction: column;
    max-width: 80%;
    padding-bottom: 2rem;
    border-bottom: 2px dashed white;
  }

  .algorithm-diplayer:last-of-type {
    border-bottom-style: none;
  }
}

.algorithm-diplayer > .info {
  display: grid;
  place-items: center;
  width: 100%;
}

.algorithm-diplayer > .info > h3 {
  margin: 0;
  color: var(--tertiary);
  width: 100%;
}

.algorithm-diplayer > .info > .buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 0.5rem;
}

.algorithm-diplayer > .info > .buttons > button {
  width: 100%;
  height: 2rem;
  border-radius: 0.25rem;
  background-color: var(--secondary);
  color: var(--primary);
  font-weight: 600;
}

.algorithm-diplayer > .info > .buttons > button:hover {
  cursor: pointer;
  filter: brightness(0.75);
}

.algorithm-diplayer > .info > .buttons > button:disabled {
  filter: brightness(0.5);
}

.maze-container {
  border: 2px solid var(--tertiary);
}

.maze-row {
  display: flex;
  flex-direction: row;
}

.maze-row > .cell {
  height: 1.5rem;
  width: 1.5rem;
  background-color: var(--primary);
}

.maze-row > .cell-twall {
  border-top: 2px solid var(--tertiary);
}

.maze-row > .cell-rwall {
  border-right: 2px solid var(--tertiary);
}

.maze-row > .cell-bwall {
  border-bottom: 2px solid var(--tertiary);
}

.maze-row > .cell-lwall {
  border-left: 2px solid var(--tertiary);
}
