html {
  scroll-behavior: smooth;
}

.home {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 100%;
}

.home > section {
  padding-top: var(--vertical-spacing-regular);
}

@media only screen and (max-width: 900px) {
  .home > section {
    padding-top: var(--vertical-spacing-mobile);
  }
}

/* Card */
.intro {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
}

.intro-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 2rem;
  padding: 0;
}

.intro-card li {
  list-style: none;
  justify-content: center;
  width: 50%;
}

.home .circle {
  display: inline-block;
  position: relative;
  background-color: var(--secondary);
  width: 15rem;
  border-radius: 3rem;
}

.home .circle .pfp {
  max-width: 100%;
  object-fit: contain;
  object-position: bottom right;
  border-radius: 3rem;
}

.intro-msg {
  font-size: 3rem;
}

@media only screen and (max-width: 900px) {
  .intro-card {
    flex-direction: column-reverse;
    align-content: center;
  }

  .intro-card li {
    width: 80%;
  }

  .home .circle {
    width: 100%;
  }

  .intro-msg {
    font-size: 2em;
  }
}

/* About */
.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
}

.about ul {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  padding: 0;
  gap: 4rem;
  margin: 0;
}

.about ul li {
  list-style: none;
  background: linear-gradient(
    to top right,
    var(--background-lighter) 50%,
    var(--background-darker) 50%
  );
  background-size: 200% 200%;
  background-position: 0% 50%;
  transition: 0.1s;
  border: 1px solid var(--tertiary);
  padding: 1rem;
  width: calc(50% - 2rem);
  max-width: 25rem;
  border-radius: 1rem;
}

.about ul li:hover {
  background-position: 100% 0%;
  transition: 0.3s;
}

.about ul li .icon {
  max-width: 3rem;
  max-height: 3rem;
}

@media only screen and (max-width: 900px) {
  .about ul {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .about ul li {
    width: 100%;
  }
}

/* Technologies */

.technologies {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.technologies ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 3rem;
  padding: 4rem;
  margin: 0;
  background-color: var(--background-lighter);
}

.technologies ul li {
  list-style: none;
  animation: hovering 2s ease-in-out 0s infinite alternate;
}

.technologies ul li img {
  max-width: 4rem;
  vertical-align: middle;
  transition: 0.3s;
  filter: drop-shadow(0 1rem 0.25rem black);
}

@keyframes hovering {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-0.25rem);
  }
}

.technologies ul li img:hover {
  transition: 0.3s;
  transform: scale(1.25);
}

/* Projects */

.projects {
  width: 80%;
  padding-top: var(--vertical-spacing-regular);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.projects ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 2rem;
}

.projects ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  transition: 0.3s;
  width: 100%;
}

.projects ul li:hover {
  transform: scale(1.01);
}

.projects ul li .project-card {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background-color: var(--background-lighter);
  border-radius: 1rem;
  max-width: 35rem;
  min-height: 15rem;
}

.projects ul li .project-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem 1rem;
  width: 100%;
}

.projects ul li .project-info h3 {
  color: var(--primary);
  margin: 0;
}

.projects ul li .project-info .buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 1rem;
  width: 100%;
}

.projects ul li .project-info .buttons a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  color: var(--primary);
  border-radius: 0.5rem;
  height: 2rem;
  width: 4rem;
  font-weight: 500;
  transition: 0.3s;
}

.projects ul li .project-info .buttons a:hover {
  filter: brightness(1.1);
}

.projects ul li .project-info .buttons .demo {
  background-color: var(--secondary);
}

.projects ul li .project-info .buttons .source {
  border: 2px solid var(--quaternary);
}

.projects ul li img {
  max-width: 20rem;
  border-radius: 0 1rem 1rem 0;
  filter: grayscale(80%);
  transition: 0.3s;
}

.projects ul li:hover img {
  filter: grayscale(0%);
}

@media only screen and (max-width: 900px) {
  .projects ul li .project-card {
    max-width: 80%;
    flex-direction: column-reverse;
    align-items: center;
  }

  .projects ul li img {
    max-width: 100%;
    border-radius: 1rem 1rem 0 0;
  }
}

/* Contact */

.contact {
  width: 100%;
  max-width: 50rem;
}

.contact > ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0;
  margin: 4rem 0;
}

.contact > ul > li {
  padding: 0;
  margin: 0;
}

.contact > ul > li > a {
  text-decoration: none;
  color: var(--primary);
}
