:root {
  /* Font colors */
  --primary: #ffffff;
  --secondary: #e71d36;
  --tertiary: #269f93;
  --quaternary: #ff9f1c;
  --quinary: #e0fbfc;

  /* Background color */
  --background: #011627;
  --background-darker: #010617;
  --background-lighter: #112637;

  /* Font */
  font-size: 18px;

  /* Spacing */
  --navbar-width-desktop: 12.5rem;
  --navbar-height-mobile: 5rem;
  --vertical-spacing-regular: 4rem;
  --vertical-spacing-mobile: 2rem;
}

* {
  box-sizing: border-box;
}

body {
  background-color: var(--background);
  line-height: 1.5;
  margin: 0;

  color: var(--primary);

  font-family: "Roboto", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Inconsolata", monospace;
}

h1 {
  font-size: 3rem;
  color: var(--primary);
  letter-spacing: 0.1rem;
  font-weight: 500;
}

h2 {
  font-size: 2rem;
  color: var(--primary);
  letter-spacing: 0.1rem;
  font-weight: 500;
  text-decoration: underline;
}

h3 {
  font-size: 1.5rem;
  color: var(--secondary);
}
