.page-404 {
  height: 75vh;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.page-404 .inner-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-404 .inner-container h1 {
  margin: 1rem 0;
}

.page-404 .inner-container .link {
  color: var(--primary);
}
