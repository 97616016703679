.main {
  padding-left: var(--navbar-width-desktop);
}

@media only screen and (max-width: 900px) {
  .main {
    padding-left: 0;
    padding-top: var(--navbar-height-mobile);
  }
}
