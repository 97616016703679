.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2rem;
}

#unity-container {
  width: 100%;
  max-width: 50rem;
  border: 4px solid var(--tertiary);
  background-color: var(--primary);
}

.screen-container {
  width: 100%;
  display: grid;
  place-items: center;
  background: #231f20;
}

.player-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
}

.unity-player {
  position: absolute;
  width: 100%;
  height: 100%;
}

#unity-loading-bar {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  display: none;
}

#unity-logo {
  width: 154px;
  height: 130px;
  background: url("unity-logo-dark.png") no-repeat center;
}

#unity-progress-bar-empty {
  width: 141px;
  height: 18px;
  margin-top: 10px;
  margin-left: 6.5px;
  border: 2px solid var(--primary);
  z-index: 10;
}

#unity-progress-bar-full {
  min-width: 0%;
  height: 100%;
  background-color: var(--primary);
  z-index: 11;
}

#unity-footer {
  position: relative;
  height: 38px;
  margin: 0.5rem;
}

#unity-webgl-logo {
  float: left;
  width: 204px;
  height: 38px;
  background: url("webgl-logo.png") no-repeat center;
}

#unity-build-title {
  float: right;
  margin-right: 10px;
  line-height: 38px;
  font-family: arial;
  font-size: 18px;
}

#unity-fullscreen-button {
  float: right;
  width: 38px;
  height: 38px;
  background: url("fullscreen-button.png") no-repeat center;
}

#unity-warning {
  position: absolute;
  left: 50%;
  top: 5%;
  transform: translate(-50%);
  background: white;
  padding: 10px;
  display: none;
}
