.nav {
  display: flex;
  flex-direction: column;
  position: fixed;
  align-items: center;
  text-align: center;
  height: 100%;
  max-width: var(--navbar-width-desktop);
  background-color: var(--background-darker);
  z-index: 10;
}

.nav a {
  color: var(--primary);
  text-decoration: none;
  padding: 1rem 1rem;
  font-size: 2rem;
  width: 100%;
}

.nav ul {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 0.5rem;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

.nav ul li a {
  font-size: 1rem;
  padding: 0 4rem;
}

.nav li:hover {
  background-color: var(--background-lighter);
}

.nav .mobile-nav {
  display: none;
}

.nav .desktop-nav {
  width: 100%;
}

@media only screen and (max-width: 900px) {
  .nav {
    flex-direction: row;
    width: 100%;
    max-width: none;
    text-align: left;
    justify-content: space-between;
    height: var(--navbar-height-mobile);
    margin: 0;
    padding: 0.5rem;
  }

  .nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .nav ul {
    position: fixed;
    left: 0;
    top: var(--navbar-height-mobile);
    padding: 1rem 0;
    border-top: 2px solid var(--primary);
    flex-direction: column;
    gap: 1rem;
    background-color: var(--background-darker);
  }

  .nav .mobile-nav {
    display: flex;
  }

  .nav .desktop-nav {
    display: none;
  }

  .nav .Icon {
    margin-right: 0.5rem;
  }

  .nav .Icon:hover {
    cursor: pointer;
  }

  .nav ul li a {
    padding: 0 2rem;
  }
}
